<template>
    <div :class="wrapperClass">
        <label v-if="label">{{label}}</label>
        <i v-if="tooltip != null" v-tooltip.top="{value:tooltip, class: 'custom-alert'}" style="margin-left: .4rem;" class="pi pi-question-circle"></i>
        <InputNumber 
        v-if="mode == 'currency'"
        ref="control"
        :mode="mode" 
        :prefix="prefix"
        :currency="currency" 
        :readonly="readonly"
        :disabled="disabled"
        locale="es-MX"
        :min="min"
        :max="max" 
        :class="{ 'p-invalid': valid === false }"
        v-model="localValue"
        @keydown.tab.exact="$emit('tab')"
        @keydown.shift.tab.exact="$emit('shift-tab')"
        @keydown.enter.exact="$emit('enter')"
        :showButtons="showStepper"
        :buttonLayout="buttonLayout"
        :step="step"
        decrementButtonClass="p-button-primary" 
        incrementButtonClass="p-button-primary" 
        incrementButtonIcon="pi pi-plus" 
        decrementButtonIcon="pi pi-minus"
        @input="$emit('update:modelValue', $event.value)" />
        <InputNumber 
        ref="control"
        :prefix="prefix"
        :readonly="readonly"
        :disabled="disabled"
        v-else :class="{ 'p-invalid': valid === false }"
        v-model="localValue"
        :min="min"
        :max="max"
        :minFractionDigits="minFractionDigits" 
        :maxFractionDigits="maxFractionDigits"
        :showButtons="showStepper"
        :buttonLayout="buttonLayout"
        :step="step"
        decrementButtonClass="p-button-primary" 
        incrementButtonClass="p-button-primary" 
        incrementButtonIcon="pi pi-plus" 
        decrementButtonIcon="pi pi-minus"
        @keydown.tab.exact="$emit('tab')"
        @keydown.shift.tab.exact="$emit('shift-tab')"
        @keydown.enter.exact="$emit('enter')"
        @input="$emit('update:modelValue', $event.value)" />
        <small class="p-invalid" v-if="valid === false"> 
            {{validation}}
        </small>
    </div>
</template>

<script>
export default {
  expose: ['focus'],
  props: {
    tooltip: {
        type: [String, null],
        default: null
    },
    step: {
      type: Number,
      default: 1
    },
    showStepper: {
      type: Boolean,
      default: null
    },
    modelValue: {
      type: [String, Number, null],
      required: true,
    },
    buttonLayout: {
      type: String,
      default: 'horizontal'
    },
    prefix: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: "decimal"
    },
    wrapperClass: {
        type: String,
        default: "field"
    },
    label: {
        type: String,
        default: null
    },
    minFractionDigits: {
      type: Number,
      default: null,
    },
    maxFractionDigits: {
      type: Number,
      default: null,
    },
    valid: {
        type: Boolean,
        //Todos son validos antes del submit
        default: true
    },
    disabled: {
        type: Boolean,
        default: false
    },
    validation: {
        type: String,
        default: "Favor de llenar el campo"
    },
    textArea: {
        type: Boolean,
        default: false
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    currency: {
      type: String,
      default: "MXN"
    }
  },
  data() {
    return {
      localValue: this.modelValue,
    };
  },
  methods: {
    focus() {
      this.$refs.control.$el.children[0].focus();
    }
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    modelValue(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style>
  .custom-alert .p-tooltip-text {
    background-color: var(--blue-900);
    color: rgb(255, 255, 255);
  }
  .custom-alert.p-tooltip-top .p-tooltip-arrow {
      border-right-color: var(--pblue-900);
  }
</style>