import {
    Abstract
} from './Abstract';

export class PaymentRequestDetail extends Abstract {
    constructor(session) {
        super('payment_request_detail/PaymentRequestDetail', session);
        this.id_number = null;
        this.id_payment_request = null;
        this.id_ledger_account = null;
        this.id_type_assistant = null;
        this.assistant = null;
        this.id_cost_center = null;
        this.reference = null;
        this.charge = 0.00;
        this.accounting_payment = 0.00;
        this.concept_name = null;

        /* Campos adicionales */
        this.leader_account_key = null;
        this.leader_account_name = null;
        this.cost_center_name  = null;
    }
}