import {
    Abstract
} from './Abstract';


import axios from "axios";

export class PaymentRequest extends Abstract {
    constructor(session) {
        super('payment_request/PaymentRequest', session);
        this.id_bank_account = null;
        this.date_request = null;
        this.date_pay = null;
        this.status = 1;
        this.id_supplier = null;
        this.supplier_name = null;
        this.total = 0.00;
        this.invoices = null;
        this.concept_name = null;
        this.id_currency = null;
        this.exchange_rate = 0.00;
        this.user_request = null;
        this.user_checkout = null;
        this.user_authorize = null;
        this.check_folio = null;
        this.checkbook = null;
        this.type_of_request = null;
        this.id_policy = null;
        this.id_nature = null;
        this.id_bank = null;
        this.clabe = null;
        this.id_cost_center = null;
        this.reconciled_total = 0.00;
        this.id_payment_condition = null;
        this.payment_request_details = [];

        /*Campos adicionales */
        this.bank_account_name = null;
    }

    async post() {
        let response = await axios.post(this.controller + '/post', this, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async concepts () {
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una solicitud de pago para recibir sus conceptos"
        let response = await axios.get(this.controller + '/concepts/' + this.id, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async generate() {
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una solicitud de pago para generar"
        let response = await axios.post(this.controller + '/generate/' + this.id, null, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

}