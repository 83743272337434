import {
    Abstract
} from './Abstract';

export class BankAccount extends Abstract {
    constructor(session) {
        super('accountsreceivable/BankAccount', session);
        this.name = null;
        this.key_name = null;
        this.type = null;
        this.id_bank = null;
        this.key_banking = null;
        this.id_currency = null;
        this.id_accounting_account = null;
        this.id_complementary_accounting_account = null;
        this.id_supplier = null;
        this.id_supplier_concept = null;
        this.id_cost_center = null;
        this.id_passive_accounting_account = null;
        this.id_iva_paid_accounting_account = null;
        this.id_transfer_policy_type = null;
    }
}