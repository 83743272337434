import {
    Abstract
} from './Abstract';

export class Supplier extends Abstract {
    constructor(session) {
        super('accountsreceivable/Supplier', session);
        this.name = null;
        this.key_name = null;
        this.trade_name = null;
        this.address = null;
        this.suburb = null;
        this.city = null;
        this.state = null;
        this.country = null;
        this.zip_code = 0;
        this.telephone = null;
        this.collection_email = null;
        this.rfc = null;
        this.curp = null;
        this.contact = null;
        this.sale_email = null;
        this.active = 0;
        this.id_accounting_account = null;
        this.id_accounting_account_key = null;
        this.id_accounting_account_name = null;
    }
}