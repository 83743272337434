<template>
    <div class="card">
        <Loader v-model="loading" />
        <ConfirmDeleteDialog @closed="deleteDialog = false" v-model="deleteDialog" @deleted="deleteDetail" /> 
        <Helper v-model="paymentRequestHelper" header="Buscar Solicitud de Pago" :headers="paymentRequestHeaders"
            :rows="paymentRequests" @selected="selectPaymentRequest" />
        <Helper v-model="supplierHelper" header="Buscar Proveedor" :headers="supplierHeaders" :rows="suppliers"
            @selected="selectSupplier" />
        <Helper v-model="bankHelper" header="Buscar Banco" :headers="bankHeaders" :rows="banks"
            @selected="selectBank" />
        <Helper v-model="bankAccountHelper" header="Buscar Cuenta Bancaria" :headers="bankAccountHeaders" :rows="bankAccounts"
            @selected="selectBankAccount" />
        <Helper v-model="costCenterHelper" header="Buscar Centro de Costos" :headers="costCenterHeaders" :rows="costsCenters"
            @selected="selectCostsCenter" />
        <Helper v-model="costCenterDetailHelper" header="Buscar Centro de Costos" :headers="costCenterHeaders" :rows="costsCentersDetail"
            @selected="selectCostsCenterDetail" />
        <Helper v-model="ledgerAccountHelper" header="Buscar Cuenta Contable" :headers="ledgerAccountHeaders" :rows="ledgerAccounts"
            @selected="selectLedgerAccount" />
        <Helper v-model="ledgerAccountUpdateHelper" header="Actualizar Cuenta Contable" :headers="ledgerAccountHeaders" :rows="ledgerAccountsUpdate"
            @selected="selectLedgerAccountUpdate" />
        <Helper v-model="customerHelper" header="Buscar Cliente" :headers="customersHeaders" :rows="customers"
             @selected="selectCustomer" />
        <Dialog :style="{ width: '75%' }" :closeOnEscape="true" :closable="true" :modal="true" header="Modificar Ubicacion Origen" v-model:visible="originLocationDialog">
              <div class="p-fluid formgrid grid">
                          
              </div>
        </Dialog>
      <Dialog v-model:visible="modalPolicy" :closable="true" :style="{ width: '75vw' }" :modal="true">
        <PolicyUI @update="policyUpdated" :id="id_policy" />
    </Dialog>
    <Dialog :style="{ width: '75%' }" :closeOnEscape="true" :closable="true" :modal="true" header="Modificar concepto" v-model:visible="detailDialog">
    <div class="p-fluid formgrid grid">
                              <FormInputText v-if="!isHidden" wrapperClass="field col-2" label="Id" v-model="detailUpdate.id_ledger_account"  />
                              <div class="field col-2">
                                <label>Cuenta Contable</label>
                                <div class="p-inputgroup">
                                    <InputText :readonly="true" v-model="detailUpdate.leader_account_key" />
                                    <Button @click="ledgerAccountUpdateHelper.visible = true" icon="pi pi-search"
                                        class="p-button-primary" />
                                </div>
                            </div>
                             <FormInputText wrapperClass="field col-4" label="Nombre Cuenta Contable" v-model="detailUpdate.leader_account_name" />
                            <FormDropdown wrapperClass="field col-2" label="Tipo Auxiliar" v-model="detailUpdate.id_type_assistant" 
                           :options="typesassistant" optionLabel="text" optionValue="value" />
                           <div v-if="detailUpdate.id_type_assistant != '0'" class="field col-2">
                                <label>Auxiliar</label>
                                <div class="p-inputgroup">
                                    <InputText  :readonly="true" v-model="detailUpdate.assistant" />
                                    <Button @click="customerHelper.visible = true" icon="pi pi-search"
                                        class="p-button-primary" />
                                </div>
                            </div>
                            <div class="field col-2">
                                <label>C.C.</label>
                                <div class="p-inputgroup">
                                    <InputText :readonly="true" v-model="detailUpdate.id_cost_center" />
                                    <Button @click="costCenterDetailHelper.visible = true" icon="pi pi-search"
                                        class="p-button-primary" />
                                </div>
                            </div>
                            <FormInputNumber wrapperClass="field col-2" label="Cargo"
                                v-model="detailUpdate.charge" mode="currency" />
                            <FormInputNumber wrapperClass="field col-2" label="Abono"
                                v-model="detailUpdate.accounting_payment" mode="currency" />
                            <FormInputText wrapperClass="field col-4" label="Concepto" v-model="detailUpdate.concept_name" />
                            <div class="field col-2"></div>
                            <div class="field col-2">
                               <br>
                                <Button @click="actualizaDetail">Actualizar</Button>
                            </div>
                        </div>
        </Dialog>        
        <Panel header="Solicitud de Pago">
            <BasicFormToolbar :actions="['new', 'save']" @new="openNew" @save="save">
            <template #custom-left>
                    <Button label="Generar" icon="pi pi-money-bill" class="p-button-info mr-1" @click="generatePayment" />
                    <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger mr-1" @click="certificateInvoice" />
                    <Button label="Cancelar" icon="pi pi-times" class="p-button-danger mr-1" @click="downloadXML" />
                </template>
            </BasicFormToolbar>
            <div class="grid">
                <div class="col-12">
                    <Fieldset legend="Informacion General">
                        <div class="p-fluid formgrid grid">
                            <div class="field col-2">
                                <label>Folio</label>
                                <div class="p-inputgroup">
                                    <InputText :readonly="true" v-model="entity.id" />
                                    <Button @click="paymentRequestHelper.visible = true" icon="pi pi-search"
                                        class="p-button-primary" />
                                </div>
                            </div>
                            <div class="field col-2">
                                <label>Fecha Solicitud</label>
                                <Calendar dateFormat="dd/mm/yy" v-model="entity.date_request" :showIcon="true"
                                    :class="{ 'p-invalid': validate.validations.date_request === false }" />
                            </div>
                            <div class="field col-2">
                                <label>Fecha Pago</label>
                                <Calendar dateFormat="dd/mm/yy" v-model="entity.date_pay" :showIcon="true"
                                    :class="{ 'p-invalid': validate.validations.date_pay === false }" />
                            </div>
                             <div class="field col-2">
                               <Status :image="status.image" :severity="status.severity" :status="status.status" />
                            </div>
                        </div>
                    </Fieldset>
                </div>
                <div class="col-12">
                    <Fieldset legend="Datos Proveedor">
                        <div class="p-fluid formgrid grid">
                            <div class="field col-1">
                                <label>Folio</label>
                                <div class="p-inputgroup">
                                    <InputText :readonly="true" v-model="entity.id_supplier" />
                                    <Button @click="supplierHelper.visible = true" icon="pi pi-search"
                                        class="p-button-primary" />
                                </div>
                            </div>
                            <FormInputText wrapperClass="field col-4" label="Nombre"
                                v-model="entity.supplier_name" :valid="validate.validations.supplier_name" />
                            <div class="field col-1">
                                <label>Banco</label>
                                <div class="p-inputgroup">
                                    <InputText :readonly="true" v-model="entity.id_bank" />
                                    <Button @click="bankHelper.visible = true" icon="pi pi-search"
                                        class="p-button-primary" />
                                </div>
                            </div>
                            <div class="field col-1">
                                <label>Cuenta</label>
                                <div class="p-inputgroup">
                                    <InputText :readonly="true" v-model="entity.id_bank_account" />
                                    <Button @click="bankAccountHelper.visible = true" icon="pi pi-search"
                                        class="p-button-primary" />
                                </div>
                            </div>
                            <FormInputText wrapperClass="field col-3" label="Nombre Cuenta Bancaria"
                                v-model="entity.bank_account_name" :valid="validate.validations.bank_account_name" />
                            <FormInputText wrapperClass="field col-2" label="Clabe Cuenta"
                                v-model="entity.clabe" :valid="validate.validations.clabe" />
                        </div>
                    </Fieldset>
                </div>
                <div class="col-12">
                    <Fieldset legend="Datos Generales">
                        <div class="p-fluid formgrid grid">
                             <div class="field col-2">
                                <label>Centro Costos</label>
                                <div class="p-inputgroup">
                                    <InputText :readonly="true" v-model="entity.id_cost_center" />
                                    <Button @click="costCenterHelper.visible = true" icon="pi pi-search"
                                        class="p-button-primary" />
                                </div>
                            </div>
                            <FormDropdown wrapperClass="field col-2" label="Tipo de Solicitud" v-model="entity.type_of_request" 
                            :valid="validate.validations.type_of_request" :options="typerequest" optionLabel="text" optionValue="value" />
                           <FormDropdown wrapperClass="field col-2" label="Naturaleza" v-model="entity.id_nature" 
                            :options="natures" optionLabel="text" optionValue="value" />
                          <div class="field col-2">
                                <label for="sat">Moneda</label>
                                <Dropdown :filter="true" v-model="entity.id_currency" :options="c_Moneda" optionLabel="c_Moneda" optionValue="c_Moneda" :class="{ 'p-invalid': validate.validations.id_currency === false }">
                                    <template #option="slotProps">
                                        <div>
                                            <div>{{ slotProps.option.c_Moneda }} - {{ slotProps.option.Descripcion.toUpperCase() }}</div>
                                        </div>
                                    </template>
                                </Dropdown>
                                <small class="p-invalid" v-if="validate.validations.id_currency === false"> Favor de llenar el campo </small>
                            </div>
                            <FormInputNumber wrapperClass="field col-2" label="Tipo de Cambio"
                                v-model="entity.exchange_rate" mode="currency" :valid="validate.validations.exchange_rate" />
                            <FormInputNumber wrapperClass="field col-2" label="Total" v-model="entity.total"
                                :valid="validate.validations.total" mode="currency" />
                            <FormInputText wrapperClass="field col-2" label="Facturas" v-model="entity.invoices"
                                :valid="validate.validations.invoices" mode="currency" />
                            <FormInputText wrapperClass="field col-2" label="Concepto" v-model="entity.concept_name"
                                :valid="validate.validations.concept_name" mode="currency"  />
                        </div>
                    </Fieldset>
                </div>
                <div class="col-12">
                    <Fieldset legend="Conceptos">
                        <div class="p-fluid formgrid grid">
                              <FormInputText v-if="!isHidden" wrapperClass="field col-2" label="Id" v-model="detail.id_ledger_account"  />
                              <div class="field col-2">
                                <label>Cuenta Contable</label>
                                <div class="p-inputgroup">
                                    <InputText :readonly="true" v-model="detail.leader_account_key" />
                                    <Button @click="ledgerAccountHelper.visible = true" icon="pi pi-search"
                                        class="p-button-primary" />
                                </div>
                            </div>
                             <FormInputText wrapperClass="field col-3" label="Nombre Cuenta Contable" v-model="detail.leader_account_name" />
                            <FormDropdown wrapperClass="field col-1" label="Tipo Auxiliar" v-model="detail.id_type_assistant" 
                             :options="typesassistant" optionLabel="text" optionValue="value" />
                           <div v-if="detail.id_type_assistant != '0'" class="field col-1">
                                <label>Auxiliar</label>
                                <div class="p-inputgroup">
                                    <InputText  :readonly="true" v-model="detail.assistant" />
                                    <Button @click="customerHelper.visible = true" icon="pi pi-search"
                                        class="p-button-primary" />
                                </div>
                            </div>
                            <div class="field col-1">
                                <label>C.C.</label>
                                <div class="p-inputgroup">
                                    <InputText :readonly="true" v-model="detail.id_cost_center" />
                                    <Button @click="costCenterDetailHelper.visible = true" icon="pi pi-search"
                                        class="p-button-primary" />
                                </div>
                            </div>
                            <FormInputNumber wrapperClass="field col-1" label="Cargo"
                                v-model="detail.charge" mode="currency" />
                            <FormInputNumber wrapperClass="field col-1" label="Abono"
                                v-model="detail.accounting_payment" mode="currency" />
                            <FormInputText wrapperClass="field col-2" label="Concepto" v-model="detail.concept_name" />
                        </div>
                         <div class="p-fluid formgrid grid">
                            <div class="field col-12">
                                <Button @click="saveDetail" icon="pi pi-save" label="Guardar" />
                            </div>
                            <div class="field col-12">
                                <BasicDatatable 
                                :rowaction="true"
                                :rowedit="true"
                                :rowdelete="true" 
                                fontsize="small"
                                @edited="selectDetail"
                                @deleted="confirmDelete"
                                :headers="detailsHeaders" 
                                :rows="entity.payment_request_details" />
                            </div>
                        </div>
                    </Fieldset>
                </div> 
            </div>
    </Panel>
    </div>
</template>

<script>
import { Branch } from '../../../models/general/Branch';
import { Rule, fillObject, ErrorToast, satCatalogo, HeaderGrid, validate } from '../../../utilities/General';
import Loader from '../../../components/general/Loader.vue';
import Helper from '../../../components/general/HelperDialog.vue';
import FormInputText from '../../../components/general/FormInputText.vue';
import FormInputNumber from '../../../components/general/FormInputNumber.vue';
import Session from '../../../mixins/sessionMixin';
import { PaymentRequest } from '../../../models/cxp/PaymentRequest';
import { PaymentRequestDetail } from '../../../models/cxp/PaymentRequestDetail';
import { Supplier } from '../../../models/cxp/Supplier';
import { Bank } from '../../../models/cxp/Bank';
import { BankAccount } from '../../../models/cxp/BankAccount';
import { AccountingCostCenter } from '../../../models/contabilidad/AccountingCostCenter';
import { AccountingLedgerAccount } from '../../../models/contabilidad/AccountingLedgerAccount';
import { Customer } from '../../../models/comercial/Customer';
import FormDropdown from '../../../components/general/FormDropdown.vue';
import BasicDatatable from '../../../components/general/BasicDatatable.vue';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import PolicyUI from '../../Contabilidad/Aplicaciones/AccountingPolicy.vue';
import Status from "../../../components/general/Status.vue";
// import DeleteDialogComponent from '../../../components/general/DeleteDialog.vue';
export default {
    mixins: [Session],
    props: {
        id: null,
        view: null,
    },
    data() {
        return {
            deleteDialog: false,
            newConcept: false,
            entity: null,
            entities: [],
            paymentRequests: [],
            suppliers: [],
            banks: [],
            bankAccounts: [],
            costsCenters:[],
            costsCentersDetail:[],
            ledgerAccounts:[],
            ledgerAccountsUpdate:[],
            customers: [],
            c_Moneda: [],
            isHidden: true,
            detailDialog: false,
            modalPolicy: false,
            id_policy: null,
            id_editing: null,
            typerequest: [
                { value: 1, text: 'Cheque' },
                { value: 2, text: 'Transferencia' },
                { value: 3, text: 'Orden de Pago' },
            ],
            natures: [
                { value: 1, text: 'Pago de Factura' },
                { value: 2, text: 'Solicitud de Anticipo (Deudor)' },
                { value: 3, text: 'Reembolso de Facturas (Proveedor)' },
            ],
            typesassistant: [
                { value: 1, text: 'Cliente' },
                { value: 2, text: 'Proveedor' },
                { value: 3, text: 'Concepto' },
                { value: 4, text: 'Empleado' },
            ],
            paymentRequestHeaders: [new HeaderGrid('Folio', 'id'), new HeaderGrid('Fecha', 'date'), new HeaderGrid('Proveedor', 'supplier_name'), new HeaderGrid('Moneda', 'id_currency'), new HeaderGrid('Total', 'total'), new HeaderGrid('Estatus', 'status')],
            supplierHeaders: [new HeaderGrid('Folio', 'id'), new HeaderGrid('Nombre', 'name'), new HeaderGrid('RFC', 'rfc')],
            bankHeaders: [new HeaderGrid('Folio', 'id'), new HeaderGrid('Clave', 'key_name'), new HeaderGrid('Nombre', 'name'), new HeaderGrid('RFC', 'rfc')],
            bankAccountHeaders: [new HeaderGrid('Folio', 'id'), new HeaderGrid('Clave', 'key_name'), new HeaderGrid('Nombre', 'name')],
            costCenterHeaders: [new HeaderGrid('Folio', 'id'), new HeaderGrid('Clave', 'key_name'), new HeaderGrid('Nombre', 'name')],
            ledgerAccountHeaders: [new HeaderGrid('Folio', 'id'), new HeaderGrid('Clave', 'id_key'), new HeaderGrid('Nombre', 'name')],
            customersHeaders: [new HeaderGrid('Nombre', 'name'), new HeaderGrid('Apellidos', 'last_name'), new HeaderGrid('RFC', 'rfc')],
            detailsHeaders: [new HeaderGrid('#', 'id_number'), new HeaderGrid('Clave Contable', 'leader_account_key'), 
            new HeaderGrid('Nombre Cuenta Contable', 'leader_account_name'), new HeaderGrid('Centro Costo', 'cost_center_name')
            , new HeaderGrid('Cargo', 'charge', { type: 'currency'}), new HeaderGrid('Abono', 'accounting_payment', { type: 'currency'}), new HeaderGrid('Concepto', 'concept_name')],
            rules: [
                new Rule({ name: 'id_bank_account' }),
                new Rule({ name: 'date_request' }),
                new Rule({ name: 'date_pay' }),
                new Rule({ name: 'id_supplier' }),
                new Rule({ name: 'supplier_name' }),
                new Rule({ name: 'total' }),
                new Rule({ name: 'invoices' }),
                new Rule({ name: 'concept_name' }),
                new Rule({ name: 'id_currency' }),
                new Rule({ name: 'exchange_rate' }),
                new Rule({ name: 'type_of_request' }),
                new Rule({ name: 'id_nature' }),
            ],
            rules_detail: [
            ],
            branch: new Branch(this.session),
            detail: new PaymentRequestDetail(this.session),
            detailUpdate: new PaymentRequestDetail(),
            detailDelete:  new PaymentRequestDetail(),
            validate: {
                valid: false,
                validations: {
                    type_of_request: null,
                },
            },
            validate_detail: {
                valid: false,
                validations: {
                    id_payment_request: null,
                    id_ledger_account: null,
                    concept_name: null,
                },
            },
            validate_detail_update: {
                valid: false,
                validations: {
                    id_payment_request: null,
                    id_ledger_account: null,
                    concept_name: null,
                },
            },
            loading: false,
            paymentRequestHelper: {
                visible: false,
            },
            supplierHelper: {
                visible: false,
            },
            bankHelper: {
                visible: false,
            },
            bankAccountHelper: {
                visible: false,
            },
            costCenterHelper: {
                visible: false,
            },
            costCenterDetailHelper: {
                visible: false,
            },
            ledgerAccountHelper: {
                visible: false,
            },
            ledgerAccountUpdateHelper: {
                visible: false,
            },
             customerHelper: {
                visible: false,
            },
        };
    },
    components: { Status, FormInputNumber, BasicFormToolbar, FormDropdown, PolicyUI, Loader, Helper, FormInputText , BasicDatatable},
    created() {
        this.entity = new PaymentRequest(this.session);
    },
    async mounted() {
        await this.refresh();
        this.loading = true;
        //Cargamos la sucursal
        this.branch.id = this.session.branch;
        this.branch = fillObject(this.branch, await this.branch.retrieve());
        //Aqui cargamos los CATALOGOS ESTATICOS
        (this.c_Moneda = await satCatalogo(9)), (this.loading = false);
    },
    computed: {
    status(){
      if (this.entity.status == 1) {
        return {
          severity: 'primary',
          status: 'Registrado',
          image: "processing.png"
        } 
      } else if (this.entity.status == 2) {
        return {
          severity: 'primary',
          status: 'Aprobado',
          image: "approved.png"
        } 
      } else if (this.entity.status == 3) {
        return {
          severity: 'primary',
          status: 'Autorizado',
          image: "authorized.png"
        } 
      } else if (this.entity.status == 4) {
        return {
          severity: 'primary',
          status: 'Cheque Generado',
          image: "generated.png"
        } 
      } else if (this.entity.status == 9) {
        return {
          severity: 'primary',
          status: 'Cancelado',
          image: "cancel.png"
        } 
      } else {
        return {
          severity: 'primary',
          status: 'Registrado',
          image: "processing.png"
        } 
      }
    }
  },
    watch: {},
    methods: {
         confirmDelete(entity) {
            this.detailDelete = fillObject(this.detailDelete, entity);
            this.deleteDialog = true;
        },
         deleteDetail() {
      
            let id_number = this.detailDelete.id_number;
            this.entity.payment_request_details = this.entity.payment_request_details.filter(x => x.id_number != this.detailDelete.id_number);
            this.entity.payment_request_details.forEach(detail => {
                if (detail.id_number > id_number) {
                    detail.id_number = detail.id_number - 1;
                }
            });
            this.deleteDialog = false;
        },
        async selectPaymentRequest(paymentrequest) {
            try {
                this.loading = true;
                this.entity = fillObject(this.entity, paymentrequest);
                if (this.entity.date_request) this.entity.date_request = new Date(this.entity.date_request);
                if (this.entity.date_pay) this.entity.date_pay = new Date(this.entity.date_pay);
                 //* Llenamos los conceptos
                 this.entity.payment_request_details = await this.entity.concepts();

            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        selectSupplier(supplier) {
            this.entity.id_supplier = supplier.id;
            this.entity.supplier_name = supplier.trade_name;
        },
        selectBank(bank) {
            this.entity.id_bank = bank.id;
        },
        selectBankAccount(bankaccount) {
            this.entity.id_bank_account = bankaccount.id;
            this.entity.bank_account_name = bankaccount.name;
            this.entity.clabe = bankaccount.key_banking;
        },
        selectCostsCenter(costscenter) {
            this.entity.id_cost_center = costscenter.id;
        },
        selectCostsCenterDetail(costscenter) {
            this.detail.id_cost_center = costscenter.id;
        },
        selectLedgerAccount(ledgeraccount) {
            this.detail.id_ledger_account = ledgeraccount.id;
            this.detail.leader_account_key = ledgeraccount.id_key;
            this.detail.leader_account_name = ledgeraccount.name;
        },
        selectLedgerAccountUpdate(ledgeraccountupdate) {
            this.detailUpdate.id_ledger_account = ledgeraccountupdate.id;
            this.detailUpdate.leader_account_key = ledgeraccountupdate.id_key;
            this.detailUpdate.leader_account_name = ledgeraccountupdate.name;
        },
        selectCustomer(customer) {
            this.detail.assistant = customer.id; 
        },
        selectDetail(payload) {
            payload.update = true;
            this.detailDialog = true;
            this.detailUpdate = payload;
        },
        hideDialog() {
            this.newDialog = false;
        },
        async save() {
            try {
                //* Validacion de form
                this.loading = true;
                
                if (this.entity.id != null && this.entity.id > 0) 
                {       
                       let response = await this.entity.update({
                        id: this.entity.id,
                    });
                    this.entity.id = response.id;
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Actualizar',
                        detail: 'Informacion actualizada con exito',
                        life: 3000,
                    });
                }
                else
                {
                    let response = await this.entity.post();
                    this.entity.id = response.id;                
                    let details = await this.entity.concepts();
                    this.entity.payment_request_details.push(details);

                    this.$toast.add({
                        severity: 'success',
                        summary: 'Guardar',
                        detail: 'Informacion guardada con exito',
                        life: 3000,
                    });
                

                }
             } catch (error) {
               this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
         saveDetail() {
            try {
                
                this.validate_detail = validate(this.detail, this.rules_detail);
                if (!this.validate_detail.valid) throw 'Favor de validar los campos';

                let detail = {
                    ...this.detail,
                };

                detail.id_number = (this.entity.payment_request_details.length + 1).toString();
                this.entity.payment_request_details.push(detail);
                    this.detail = new PaymentRequestDetail(this.session);
                } catch (error) {
                    this.$toast.add(new ErrorToast(error));
                }
        },
         async generatePayment() {
            try {

                this.loading = true;
                 if (this.entity.status == 9 || this.entity.status == 4) 
                    throw "el pago debe estar en estatus 'REGISTRADO' para realizar esta accion"
                let response = await this.entity.generate();
                this.$toast.add({
                    severity: 'success',
                    summary: 'Pago Generado',
                    detail: response.mensaje,
                    life: 3000,
                });
                
                this.entity = fillObject(this.entity, await this.entity.retrieve());
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
          actualizaDetail() {
             try {
               
                this.validate_detail_update = validate(this.detailUpdate, this.rules_detail);
                if (!this.validate_detail_update.valid) throw 'Favor de validar los campos';

                let detailUpdate = {
                    ...this.detailUpdate,
                };

   
                //Actualizamos el index
                let index = this.entity.payment_request_details.findIndex(x => x == detailUpdate.id_number);
                this.entity.payment_request_details[index] = detailUpdate;
                this.detailDialog = false;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
        openPolicy() {
            console.log(this.entity.id_policy)
            this.id_policy = this.entity.id_policy ?? 0;
            this.modalPolicy = true;
        },
        policyUpdated() {
            this.modalPolicy = false;
            this.id_editing = null;
            this.id_policy = null;
        },
        async refresh() {
            try {
                this.entities = await this.entity.all();
                //Si tiene un id lo cargamos
                if (this.id) {
                    // this.entityinvoice.id = this.id;
                    // let retrieved = await this.entityinvoice.retrieve();
                    // fillObject(this.entityinvoice, retrieved);
                    // if (this.entityinvoice.date) this.entityinvoice.date = new Date(this.entityinvoice.date);
                }
                if (this.entity.date_request) this.entity.date_request = new Date(this.entity.date_request);
                if (this.entity.date_pay) this.entity.date_pay = new Date(this.entity.date_pay);
                this.paymentRequests = await new PaymentRequest(this.session).all();
                this.suppliers = await new Supplier(this.session).all();
                this.banks = await new Bank(this.session).all();
                this.bankAccounts = await new BankAccount(this.session).all();
                this.costsCenters = await new AccountingCostCenter(this.session).all();
                this.costsCentersDetail = await new AccountingCostCenter(this.session).all();
                this.ledgerAccounts = await new AccountingLedgerAccount(this.session).all();
                this.ledgerAccountsUpdate = await new AccountingLedgerAccount(this.session).all();
                this.customers = await new Customer(this.session).all();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
         async openNew() {
            try {
                this.loading = true;
                this.entity = new PaymentRequest(this.session);
                this.branch.id = this.session.branch;
                this.branch = fillObject(this.branch, await this.branch.retrieve());
                this.newDialog = true;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
}
</style>
